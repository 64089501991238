import React, { useState, useEffect } from 'react';
import {
    Button, Modal, Box, TextField, FormControl, InputLabel, Select, MenuItem,
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, FormHelperText, FormControlLabel, Snackbar, Alert, CircularProgress
} from '@mui/material';
import Switch from '@mui/material/Switch';
import { formatDate, authenticatedFetch } from '../utils/utils';

export default function People() {
    const [people, setPeople] = useState([]);
    const [modalOpen, setModalOpen] = useState(false);
    const [formData, setFormData] = useState({
        id: null,
        first_name: '',
        last_name: '',
        nickname: '',
        birthdate: '',
        phone_number: '',
        off_campus_status: 'none',
        enrolled: true,
        is_staff: false,
        mute_hours_violation_until: '',
        mute_personal_days_violation_until: '',
        first_day_of_attendance: ''
    });
    const [formErrors, setFormErrors] = useState({});
    const [generalError, setGeneralError] = useState('');
    const [loading, setLoading] = useState(false);
    const [showAdvancedOptions, setShowAdvancedOptions] = useState(false);

    const offCampusOptions = [
        { value: 'none', label: 'None' },
        { value: 'front_yard', label: 'Front Yard' },
        { value: 'with_company', label: 'Accompanied' },
        { value: 'full', label: 'Full' }
    ];

    useEffect(() => {
        setLoading(true);
        authenticatedFetch(`/students`) // Update endpoint if necessary
            .then(response => response.json())
            .then(data => setPeople(data))
            .catch(error => {
                console.error('Error fetching data: ', error);
                setGeneralError('Failed to fetch data');
            }).finally(() => {
                setLoading(false);
            });
    }, []);

    const handleModalOpen = (person = null) => {
        setModalOpen(true);
        if (person) {
            setFormData({
                ...person, // Spread the existing person data
                birthdate: formatDate(person.birthdate), // Ensure date is formatted if needed
                mute_hours_violation_until: person.mute_hours_violation_until || '',
                mute_personal_days_violation_until: person.mute_personal_days_violation_until || '',
                first_day_of_attendance: person.first_day_of_attendance || ''
            });
        } else {
            setFormData({
                id: null,
                first_name: '',
                last_name: '',
                nickname: '',
                birthdate: '',
                phone_number: '',
                off_campus_status: 'none',
                enrolled: true,
                is_staff: false,
                mute_hours_violation_until: '',
                mute_personal_days_violation_until: '',
                first_day_of_attendance: ''
            });
        }
    };    

    const handleModalClose = () => {
        setModalOpen(false);
        setFormData({});
        setFormErrors({});
    };

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData({
            ...formData,
            [name]: type === 'checkbox' ? checked : value
        });
    };


    const handleSubmit = async (e) => {
        e.preventDefault();

        const formErrors = {};
        if (!formData.first_name) formErrors.first_name = "First Name is required";
        if (!formData.last_name) formErrors.last_name = "Last Name is required";
        if (!formData.is_staff && !formData.birthdate) formErrors.birthdate = "Birthdate is required";
        if (!formData.is_staff && !formData.off_campus_status) formErrors.off_campus_status = "Off-Campus Status is required";
        if (formData.enrolled === "" || formData.enrolled === null) formErrors.enrolled = "Enrolled is required";
        if (formData.is_staff === "" || formData.is_staff == null) formErrors.is_staff = "Staff? is required";

        setFormErrors(formErrors)

        if (Object.keys(formErrors).length > 0) {
            return; // Stop if there are validation errors
        }

        if(formData.is_staff) {
            formData.off_campus_status = 'full';
        }

        const method = formData.id ? 'PUT' : 'POST';
        const url = formData.id ? `/students/${formData.id}` : `/students`;

        try {
            setLoading(true);

            const response = await authenticatedFetch(url, {
                method: method,
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(formData)
            });

            if (response.ok) {
                const updatedPerson = await response.json();

                if (method === 'POST') {
                    setPeople(prevPeople => {
                        const updatedPeople = [...prevPeople, updatedPerson];
                        return updatedPeople.sort((a, b) => {
                            return a.last_name.localeCompare(b.last_name) || a.first_name.localeCompare(b.first_name);
                        });
                    });
                }
                else {
                    setPeople(people.map(m => m.id === updatedPerson.id ? updatedPerson : m));
                }
                handleModalClose();
            } else {
                throw new Error('Failed to save person');
            }
        } catch (error) {
            setGeneralError('Failed to save person');
            console.error('Error saving person:', error);
        }
        finally {
            setLoading(false);
        }
    };

    // Modal style
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
    };

    const groupPeople = () => {
        const students = people.filter(p => p.enrolled && !p.is_staff).sort((a, b) => a.first_name.localeCompare(b.first_name) || a.last_name.localeCompare(b.last_name));
        const staff = people.filter(p => p.is_staff).sort((a, b) => a.first_name.localeCompare(b.first_name) || a.last_name.localeCompare(b.last_name));
        const unenrolled = people.filter(p => !p.enrolled).sort((a, b) => a.first_name.localeCompare(b.first_name) || a.last_name.localeCompare(b.last_name));

        return { students, staff, unenrolled };
    };

    const { students, staff, unenrolled } = groupPeople();

    const handleRowClick = (person) => {
        handleModalOpen(person);
    };

    const renderTableBody = (peopleArray, title) => (
        <>
            <TableRow>
                <TableCell colSpan={8} align="center" style={{ background: '#f5f5f5' }}>{title}</TableCell>
            </TableRow>
            {peopleArray.map((person) => (
                <TableRow 
                    key={person.id} 
                    onClick={() => handleRowClick(person)}
                    sx={{
                        cursor: 'pointer',
                        '&:hover': {
                            backgroundColor: '#f5f5f5',
                        },
                    }}
                >
                    <TableCell>{person.first_name}</TableCell>
                    <TableCell>{person.last_name}</TableCell>
                    <TableCell>{person.nickname}</TableCell>
                    <TableCell>{formatDate(person.birthdate)}</TableCell>
                    <TableCell>{person.phone_number}</TableCell>
                    <TableCell>{person.is_staff ? 'Yes' : 'No'}</TableCell>
                    <TableCell>{person.off_campus_status}</TableCell>
                    <TableCell>{person.enrolled ? 'Yes' : 'No'}</TableCell>
                </TableRow>
            ))}
        </>
    );

    const toggleAdvancedOptions = (e) => {
        e.preventDefault(); // Prevent form submission
        setShowAdvancedOptions(!showAdvancedOptions);
    };

    return (
        <div>
            {loading && (
                <Box sx={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    background: 'rgba(0, 0, 0, 0.3)',
                    zIndex: 'tooltip',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}>
                    <CircularProgress />
                </Box>
            )}
            <h1>People</h1>
            <Button variant="contained" onClick={() => handleModalOpen()} sx={{ mb: 2 }}>Add New Person</Button>

            <Modal
                open={modalOpen}
                onClose={handleModalClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box component="form" onSubmit={handleSubmit} noValidate sx={style}>
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="first_name"
                        label="First Name"
                        name="first_name"
                        autoFocus
                        value={formData.first_name}
                        onChange={handleChange}
                        error={!!formErrors.first_name}
                        helperText={formErrors.first_name}
                    />
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="last_name"
                        label="Last Name"
                        name="last_name"
                        value={formData.last_name}
                        onChange={handleChange}
                        error={!!formErrors.last_name}
                        helperText={formErrors.last_name}
                    />
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="nickname"
                        label="Nickname"
                        name="nickname"
                        value={formData.nickname}
                        onChange={handleChange}
                        error={!!formErrors.nickname}
                        helperText={formErrors.nickname}
                    />
                    <FormControlLabel
                        control={
                            <Switch
                                checked={formData.is_staff}
                                onChange={handleChange}
                                name="is_staff"
                                color="primary"
                            />
                        }
                        label="Staff?"
                    />
                    <FormControlLabel
                        control={
                            <Switch
                                checked={formData.enrolled}
                                onChange={handleChange}
                                name="enrolled"
                                color="primary"
                            />
                        }
                        label="Enrolled"
                    />
                    {!formData.is_staff && (
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="birthdate"
                            label="Birthdate"
                            type="date"
                            name="birthdate"
                            InputLabelProps={{ shrink: true }}
                            value={formatDate(formData.birthdate)}
                            onChange={handleChange}
                            error={!!formErrors.birthdate}
                            helperText={formErrors.birthdate}
                        />
                    )}
                    <TextField
                        margin="normal"
                        fullWidth
                        id="phone_number"
                        label="Phone Number"
                        name="phone_number"
                        value={formData.phone_number}
                        onChange={handleChange}
                    />
                    <Button
                        onClick={toggleAdvancedOptions}
                        sx={{ display: 'block', marginTop: 2, marginBottom: 2 }}
                        variant="text"
                    >
                        {showAdvancedOptions ? 'Hide Advanced Options' : 'Show Advanced Options'}
                    </Button>
                    {showAdvancedOptions && (
                        <>
                            <TextField
                                margin="normal"
                                fullWidth
                                id="first_day_of_attendance"
                                label="First Day of Attendance"
                                type="date"
                                name="first_day_of_attendance"
                                InputLabelProps={{ shrink: true }}
                                value={formData.first_day_of_attendance}
                                onChange={handleChange}
                            />
                            <TextField
                                margin="normal"
                                fullWidth
                                id="mute_hours_violation_until"
                                label="Mute Hours Violation Until"
                                type="date"
                                name="mute_hours_violation_until"
                                InputLabelProps={{ shrink: true }}
                                value={formData.mute_hours_violation_until}
                                onChange={handleChange}
                            />
                            <TextField
                                margin="normal"
                                fullWidth
                                id="mute_personal_days_violation_until"
                                label="Mute Personal Days Violation Until"
                                type="date"
                                name="mute_personal_days_violation_until"
                                InputLabelProps={{ shrink: true }}
                                value={formData.mute_personal_days_violation_until}
                                onChange={handleChange}
                            />
                        </>
                    )}
                    <Box sx={{ marginTop: '20px' }}>
                        <Button type="submit" variant="contained" color='primary'>Save</Button>
                        <Button onClick={handleModalClose} style={{ marginLeft: '10px' }}>Cancel</Button>
                    </Box>
                </Box>
            </Modal>


            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>First Name</TableCell>
                            <TableCell>Last Name</TableCell>
                            <TableCell>Nickname</TableCell>
                            <TableCell>Birthdate</TableCell>
                            <TableCell>Phone Number</TableCell>
                            <TableCell>Staff?</TableCell>
                            <TableCell>Off-Campus Status</TableCell>
                            <TableCell>Enrolled</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {renderTableBody(students, "Students")}
                        {renderTableBody(staff, "Staff")}
                        {renderTableBody(unenrolled, "Unenrolled")}
                    </TableBody>
                </Table>
            </TableContainer>
            {generalError && (
                <Snackbar open={!!generalError} autoHideDuration={6000} onClose={() => setGeneralError('')}>
                    <Alert onClose={() => setGeneralError('')} severity="error" sx={{ width: '100%' }}>
                        {generalError}
                    </Alert>
                </Snackbar>
            )}
        </div>
    );
}