// Utility functions

import { startOfWeek } from 'date-fns';

export function formatTime24To12(time24) {
    if (!time24) return ;
    const [hours24, minutes] = time24.split(':');
    const hours12 = ((parseInt(hours24, 10) + 11) % 12 + 1);
    const amPm = parseInt(hours24, 10) >= 12 ? 'PM' : 'AM';
    return `${hours12}:${minutes} ${amPm}`;
}

export const formatTimestamp24To12 = (timestamp) => {
    if (!timestamp) return "";

    const date = new Date(timestamp);
    return date.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', hour12: true });
};

export function getMonday(date) {
    return startOfWeek(date, { weekStartsOn: 1 });
}

export function formatTimestamp(formattedDate, time) {
    return time ? `${formattedDate}T${time}:00` : null;
}

export function formatDate(date) {
    if (!date) return "";

    if (!date.toISOString) {
        date = new Date(date);
    }
    
    return date.toISOString().split('T')[0];
};

function isBrowser() {
    return typeof window !== 'undefined';
}

export async function authenticatedFetch(path, options) {
    const url = [process.env.REACT_APP_API_BASE_URL, path].join('');

    if (!options) options = {};
    
    const token = (process.env.UNIVERSAL_PASSWORD && btoa(`:${process.env.UNIVERSAL_PASSWORD}`)) || localStorage.getItem('authToken');

    if (!token) {
        if (isBrowser()) {
            window.dispatchEvent(new CustomEvent('authRequired'));
        }
        else {
            throw new Error('Missing auth token');
        }
    }

    const response = await fetch(url, {
        ...options,
        headers: {
            'Authorization': `Basic ${token}`,
            ...options.headers
        }
    });

    if (response.headers.get('X-App-Version') && isBrowser()) {
        window.X_APP_VERSION = response.headers.get('X-App-Version');
    }

    if (response.status === 401) {
        if (isBrowser()) {
            window.dispatchEvent(new CustomEvent('authRequired'));
        }
        else {
            throw new Error('Unauthorized');
        }
    }

    return response;
}

//convert a Time string (HH:MM:SS) to a Date object
export function timeToDate(time) {
    const [hours, minutes, seconds] = time.split(':');
    const date = new Date();
    date.setHours(hours);
    date.setMinutes(minutes);
    date.setSeconds(seconds);
    return date;
}

export function roundToNearestTenth(num) {
    return Math.round(num * 10) / 10;
}