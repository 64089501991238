import React, { useRef, useState, useEffect } from 'react';
import {
    Button, Modal, Box, TextField, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper,
    Snackbar, Alert, CircularProgress, IconButton, Typography
} from '@mui/material';
import { authenticatedFetch } from '../utils/utils';
import { Delete } from '@mui/icons-material';

export default function SchoolCalendar() {
    const [calendars, setCalendars] = useState([]);
    const [modalOpen, setModalOpen] = useState(false);
    const [formData, setFormData] = useState({
        id: null,
        first_day: '',
        last_day: '',
        holidays: []
    });
    const [generalError, setGeneralError] = useState('');
    const [loading, setLoading] = useState(false);

    const contentRef = useRef(null);

    useEffect(() => {
        setLoading(true);
        authenticatedFetch(`/calendar`)
            .then(response => response.json())
            .then(data => setCalendars(data))
            .catch(error => {
                console.error('Error fetching calendars: ', error);
                setGeneralError('Failed to fetch calendars');
            })
            .finally(() => setLoading(false));
    }, []);

    const handleModalOpen = (calendar = null) => {
        setModalOpen(true);
    
        if (calendar) {
            setFormData({
                id: calendar.id,
                first_day: calendar.first_day,
                last_day: calendar.last_day,
                holidays: calendar.holidays || []
            });
        } else {
            setFormData({
                id: null,
                first_day: '',
                last_day: '',
                holidays: [{ date: '', name: '' }]
            });
        }
    };

    const handleModalClose = () => {
        setModalOpen(false);
        setFormData({
            id: null,
            first_day: '',
            last_day: '',
            holidays: []
        });
        setGeneralError('');
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleHolidayChange = (index, field, value) => {
        const newHolidays = [...formData.holidays];
        newHolidays[index] = {
            ...newHolidays[index],
            [field]: value
        };
        setFormData({
            ...formData,
            holidays: newHolidays
        });
    };

    const addHoliday = () => {
        const lastHoliday = formData.holidays[formData.holidays.length - 1] || { date: '', name: '' };
        
        let newDate = lastHoliday.date ? new Date(lastHoliday.date) : new Date();
        if (lastHoliday.date && !isNaN(newDate.getTime())) {
            newDate.setDate(newDate.getDate() + 1); // Increment by one day
        }
    
        setFormData({
            ...formData,
            holidays: [
                ...formData.holidays,
                { date: newDate.toISOString().split('T')[0], name: lastHoliday.name }  // Copy the name and increment the date
            ]
        });
    
        // Scroll to the bottom of the holiday list after adding
        if (contentRef.current) {
            setTimeout(() => {
                contentRef.current.scrollTop = contentRef.current.scrollHeight;
            }, 100); // Slight delay to ensure the element is rendered before scrolling
        }
    };

    const deleteHoliday = (index) => {
        const newHolidays = formData.holidays.filter((_, i) => i !== index);
        setFormData({
            ...formData,
            holidays: newHolidays
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
    
        const method = formData.id ? 'PUT' : 'POST';
        const url = formData.id ? `/calendar/${formData.id}` : `/calendar`;
    
        try {
            setLoading(true);
    
            const response = await authenticatedFetch(url, {
                method: method,
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(formData)
            });
    
            if (response.ok) {
                const updatedCalendar = await response.json();
    
                if (method === 'POST') {
                    setCalendars(prevCalendars => [...prevCalendars, updatedCalendar]);
                } else {
                    setCalendars(prevCalendars => prevCalendars.map(c => c.id === updatedCalendar.id ? updatedCalendar : c));
                }
    
                handleModalClose();
            } else {
                throw new Error('Failed to save calendar');
            }
        } catch (error) {
            setGeneralError('Failed to save calendar');
            console.error('Error saving calendar:', error);
        } finally {
            setLoading(false);
        }
    };    

    const renderTableBody = () => (
        calendars.map(calendar => (
            <TableRow key={calendar.id}>
                <TableCell>{calendar.first_day.split('-')[0]} - {calendar.last_day.split('-')[0]}</TableCell>
                <TableCell>
                    <Button onClick={() => handleModalOpen(calendar)}>Edit</Button>
                </TableCell>
            </TableRow>
        ))
    );

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '90%',
        maxHeight: '90vh',
        bgcolor: 'background.paper',
        boxShadow: 24,
        display: 'flex',
        flexDirection: 'column',
        padding: '16px',
        overflow: 'hidden',
    };
    
    const contentStyle = {
        overflowY: 'auto',
        maxHeight: '60vh',
        paddingBottom: '1rem',
    };
    
    const footerStyle = {
        display: 'flex',
        justifyContent: 'space-between',
        padding: '1rem 0',
        borderTop: '1px solid #e0e0e0',
    };
    
    return (
        <div>
            {loading && (
                <Box sx={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    background: 'rgba(0, 0, 0, 0.3)',
                    zIndex: 'tooltip',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}>
                    <CircularProgress />
                </Box>
            )}
            <h1>School Calendar</h1>
            <Button variant="contained" onClick={() => handleModalOpen()} sx={{ mb: 2 }}>Add New Calendar</Button>

            <Modal
                open={modalOpen}
                onClose={handleModalClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box component="form" onSubmit={handleSubmit} noValidate sx={style}>
                    <Box sx={contentStyle} ref={contentRef}>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="first_day"
                            label="First Day"
                            type="date"
                            name="first_day"
                            value={formData.first_day}
                            onChange={handleChange}
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="last_day"
                            label="Last Day"
                            type="date"
                            name="last_day"
                            value={formData.last_day}
                            onChange={handleChange}
                        />

                        <Typography variant="h6" gutterBottom>
                            Holidays
                        </Typography>

                        {formData.holidays.map((holiday, index) => (
                            <Box key={index} sx={{ display: 'flex', alignItems: 'center', mb: 2, gap: 2 }}>
                                <TextField
                                    margin="normal"
                                    required
                                    id={`holiday-date-${index}`}
                                    label="Date"
                                    type="date"
                                    name={`holiday-date-${index}`}
                                    value={holiday.date}
                                    onChange={(e) => handleHolidayChange(index, 'date', e.target.value)}
                                    sx={{ width: '180px' }} // Adjust the width to ensure visibility
                                />
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    id={`holiday-name-${index}`}
                                    label="Name"
                                    name={`holiday-name-${index}`}
                                    value={holiday.name}
                                    onChange={(e) => handleHolidayChange(index, 'name', e.target.value)}
                                />
                                <IconButton onClick={() => deleteHoliday(index)}>
                                    <Delete />
                                </IconButton>
                            </Box>
                        ))}
                    </Box>

                    <Box sx={footerStyle}>
                        <Button onClick={addHoliday} variant="contained" color="primary">
                            Add Holiday
                        </Button>
                        <Box sx={{ display: 'flex', gap: 2 }}>
                            <Button type="submit" variant="contained" color="primary">
                                Save
                            </Button>
                            <Button onClick={handleModalClose}>
                                Cancel
                            </Button>
                        </Box>
                    </Box>
                </Box>
            </Modal>



            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="calendar table">
                    <TableHead>
                        <TableRow>
                            <TableCell>School Year</TableCell>
                            <TableCell>Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {renderTableBody()}
                    </TableBody>
                </Table>
            </TableContainer>
            {generalError && (
                <Snackbar open={!!generalError} autoHideDuration={6000} onClose={() => setGeneralError('')}>
                    <Alert onClose={() => setGeneralError('')} severity="error" sx={{ width: '100%' }}>
                        {generalError}
                    </Alert>
                </Snackbar>
            )}
        </div>
    );
}
