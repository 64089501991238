import React, { useState, useEffect } from 'react';
import {
    Button, Modal, Box, TextField, Table, TableBody, TableCell,
    TableContainer, TableHead, TableRow, Paper, Snackbar, Alert, CircularProgress
} from '@mui/material';
import { authenticatedFetch } from '../utils/utils';

export default function OffCampusDestinations() {
    const [destinations, setDestinations] = useState([]);
    const [modalOpen, setModalOpen] = useState(false);
    const [formData, setFormData] = useState({ id: null, name: '' });
    const [generalError, setGeneralError] = useState('');
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        authenticatedFetch(`/off-campus-destinations`)
            .then(response => response.json())
            .then(data => setDestinations(data))
            .catch(error => {
                console.error('Error fetching data: ', error);
                setGeneralError('Failed to fetch data');
            }).finally(() => {
                setLoading(false);
            });
    }, []);

    const handleModalOpen = (destination = null) => {
        setModalOpen(true);
        if (destination) {
            setFormData({ id: destination.id, name: destination.name });
        } else {
            setFormData({ id: null, name: '' });
        }
    };

    const handleModalClose = () => {
        setModalOpen(false);
        setFormData({ id: null, name: '' });
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const method = formData.id ? 'PUT' : 'POST';
        const url = formData.id ? `/off-campus-destinations/${formData.id}` : `/off-campus-destinations`;

        try {
            setLoading(true);
            const response = await authenticatedFetch(url, {
                method: method,
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ name: formData.name })
            });

            if (response.ok) {
                const updatedDestination = await response.json();
                if (method === 'POST') {
                    setDestinations([...destinations, updatedDestination]);
                } else {
                    setDestinations(destinations.map(d => d.id === updatedDestination.id ? updatedDestination : d));
                }
                handleModalClose();
            } else {
                throw new Error('Failed to save destination');
            }
        } catch (error) {
            setGeneralError('Failed to save destination');
            console.error('Error:', error);
        } finally {
            setLoading(false);
        }
    };

    // Modal style
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
    };

    const handleDelete = async (destinationId) => {
        try {
            setLoading(true);
            const response = await authenticatedFetch(`/off-campus-destinations/${destinationId}`, {
                method: 'DELETE'
            });

            if (response.ok) {
                setDestinations(destinations.filter(d => d.id !== destinationId));
            } else {
                throw new Error('Failed to delete destination');
            }
        } catch (error) {
            setGeneralError('Failed to delete destination');
            console.error('Error:', error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div>
            {loading && (
                <Box sx={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    background: 'rgba(0, 0, 0, 0.3)',
                    zIndex: 'tooltip',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}>
                    <CircularProgress />
                </Box>
            )}
            <h1>Off-Campus Destinations</h1>
            <Button variant="contained" onClick={() => handleModalOpen()} sx={{ mb: 2 }}>Add New Destination</Button>

            <Modal
                open={modalOpen}
                onClose={handleModalClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box component="form" onSubmit={handleSubmit} noValidate sx={style}>
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="name"
                        label="Destination Name"
                        name="name"
                        autoFocus
                        value={formData.name}
                        onChange={handleChange}
                    />
                    <Box sx={{ marginTop: '20px' }}>
                        <Button type="submit" variant="contained" color='primary'>Save</Button>
                        <Button onClick={handleModalClose} style={{ marginLeft: '10px' }}>Cancel</Button>
                    </Box>
                </Box>
            </Modal>

            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Destination Name</TableCell>
                            <TableCell>Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {destinations.map((destination) => (
                            <TableRow key={destination.id}>
                                <TableCell>{destination.name}</TableCell>
                                <TableCell>
                                    <Button onClick={() => handleDelete(destination.id)} color="error">Delete</Button>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            {generalError && (
                <Snackbar open={!!generalError} autoHideDuration={6000} onClose={() => setGeneralError('')}>
                    <Alert onClose={() => setGeneralError('')} severity="error" sx={{ width: '100%' }}>
                        {generalError}
                    </Alert>
                </Snackbar>
            )}
        </div>
    )
};