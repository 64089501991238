// src/components/ModeSwitchModal.js
import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TableContainer, Table, TableBody, TableCell, TableRow } from '@mui/material';
import { isTablet } from 'react-device-detect';

const loadTime = new Date();

const ModeSwitchModal = ({ open, onClose, onSwitchMode, onReloadApp }) => {
  const appVersion = window.X_APP_VERSION;

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Admin</DialogTitle>
      <DialogContent>
        <TableContainer>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell component="th" scope="row">App Version</TableCell>
                <TableCell>{appVersion}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">Load Time</TableCell>
                <TableCell>{loadTime.toISOString()}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
      <DialogActions sx={{ justifyContent: isTablet ? 'center' : 'flex-end' }}>
        {!isTablet && (
          <>
            <Button onClick={() => onSwitchMode(false)}>User Mode</Button>
            <Button onClick={() => onSwitchMode(true)}>Admin Mode</Button>
          </>
        )}
        <Button onClick={() => onReloadApp(true)}>Reload App</Button>
      </DialogActions>
    </Dialog>
  );
};

export default ModeSwitchModal;